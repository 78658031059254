import React, { useState } from "react"
import PropTypes from "prop-types"
import "./layout.scss"

const Layout = ({ children }) => {

  const [ state, setState ] = useState({
    isMenuOpen: false
  })

  const handleMenu = () => {
    setState({
      isMenuOpen: !state.isMenuOpen
    })
  }

  return (
    <>
      {/* <Header siteTitle="Redirection Berlin" handleMenu={handleMenu} isMenuOpen={state.isMenuOpen}/> */}
      <div>
        <main className={state.isMenuOpen ? "open" : ""}>{children}</main>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
